import BR from 'primitives/Layout/BR';
import CatalogImage from 'components/MediaServerImage/CatalogImage';
import DummyTiles from '../DummyTiles';
import EmptyState from '../EmptyState';
import InfiniteScroll from 'react-infinite-scroller';
import NavLink from 'components/NavLink';
import OutlinedButton from 'primitives/Buttons/OutlinedButton';
import PlayButtonContainer from 'components/Player/PlayButtonContainer';
import PlayButtonContainerPrimitive from 'components/Artist/PlayButtonContainer';
import PlayerStateProxy from 'components/Player/PlayerState/PlayerStateProxy';
import PlaylistImage from 'components/MediaServerImage/PlaylistImage';
import Row from '../Row';
import SectionHeaderText from '../SectionHeaderText';
import ShouldShow from 'components/ShouldShow';
import Tile from 'components/Tile/Tile';
import TileDropdown from 'components/Tile/primitives/TileDropdown';
import Tiles from 'components/Tiles/Tiles';
import TilesImageWrapper from 'components/Tile/primitives/TilesImageWrapper';
import useMount from 'hooks/useMount';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { GrowlIcons } from 'components/Growls/constants';
import { H4, MenuButton, TilesWrapper } from '../primitives';
import { isPlaylist } from 'state/Playlist/helpers';
import { LiveRadio as LiveRadioIcon } from 'styles/icons';
import { Menu } from 'components/Tooltip';
import { showNotifyGrowl } from 'state/UI/actions';
import { TILE_RES } from 'components/MediaServerImage';
import { TILES_IN_ROW } from '../constants';
import { useDispatch } from 'react-redux';
import type { SavedStation } from 'state/YourLibrary/types';

export type Props = {
  getSavedStations: (
    params: { limit: number; offset: number },
    reset: boolean,
  ) => void;
  isSnapshot?: boolean;
  removeSavedStation: (id: string) => Promise<void>;
  savedStations: Array<SavedStation>;
};

enum SavedStationTypes {
  Artist = 'artist',
  Favorite = 'favorites',
  Live = 'live',
}

const PlayButton = PlayerStateProxy(PlayButtonContainer);

function SavedStations({
  getSavedStations,
  isSnapshot,
  removeSavedStation,
  savedStations,
}: Props) {
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [fetching, setFetching] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const dispatch = useDispatch();
  const translate = useTranslate();

  const typeMap = {
    dropdown: {
      [SavedStationTypes.Artist]: translate('Remove Artist'),
      [SavedStationTypes.Live]: translate('Remove station'),
    } as { [key in SavedStationTypes]: string },
    subtitle: {
      [SavedStationTypes.Artist]: translate('Artist Radio'),
      [SavedStationTypes.Live]: translate('Live Radio'),
    } as { [key in SavedStationTypes]: string },
  };
  const limit = 30;

  const removeStation = useCallback<(id: string, name: string) => void>(
    (id, name = '') =>
      removeSavedStation(id).then(() =>
        dispatch(
          showNotifyGrowl({
            icon: GrowlIcons.Deleted,
            title: translate('{name} removed from Your Library', { name }),
          }),
        ),
      ),
    [dispatch, removeSavedStation, translate],
  );

  const getStations = useCallback<(reset: boolean) => Promise<void>>(
    async reset => {
      setFetching(true);
      await getSavedStations({ limit, offset }, reset);
      if (!hasFetched) setHasFetched(true);
      setOffset(offset + limit);
      setFetching(false);
    },
    [getSavedStations, hasFetched, offset],
  );

  const loadMore = () => {
    if (!hasMore || fetching || !hasFetched) return;
    getStations(false);
  };

  /**
   * IHRWEB-14661: explanation of the (stations.length < offset - limit) check:
   * the current offset (which equals the last getSavedStations's offset + limit)
   * should match the number of stations, but I found AMP returns less than the limit
   * when the offset is 0. subtracting limit from offset may result in an extra
   * getSavedStations call, but it guarantees we don't miss any stations - MP
   */
  useEffect(() => {
    if (hasMore && savedStations.length < offset - limit) {
      setHasMore(false);
    }
  }, [hasMore, offset, savedStations]);

  useMount(() => {
    getStations(true);
  });

  let tiles = [...DummyTiles({ noRoundTiles: isSnapshot })];
  tiles = savedStations.map(station => {
    const { id, name, imageUrl, seedType, url } = station;
    const isRoundImage = seedType === SavedStationTypes.Artist;
    const isPlaylistType = isPlaylist(seedType);
    const subtitle = typeMap.subtitle[seedType] || '';
    const imageComponent = (
      <ShouldShow
        hiddenElement={
          <CatalogImage
            alt={name}
            aspectRatio={1}
            background
            height={TILE_RES}
            id={id}
            src={imageUrl}
            type={seedType}
            width={TILE_RES}
          />
        }
        shouldShow={isPlaylistType}
      >
        <PlaylistImage
          alt={name}
          aspectRatio={1}
          src={imageUrl}
          width={TILE_RES}
        />
      </ShouldShow>
    );

    const playButtonComponent = (
      <PlayButtonContainerPrimitive data-test="my-stations-play-button-container">
        <PlayButton
          className="play"
          deferPlay={!!url}
          seedId={id}
          stationId={id}
          stationType={seedType}
        />
      </PlayButtonContainerPrimitive>
    );

    const dropdownComponent = (
      <TileDropdown>
        <Menu>
          <Menu.List>
            <Menu.Item>
              <MenuButton
                onClick={() => removeStation(id as string, name)}
                type="button"
              >
                {typeMap.dropdown[seedType]}
              </MenuButton>
            </Menu.Item>
          </Menu.List>
        </Menu>
      </TileDropdown>
    );

    return (
      <Fragment key={id}>
        <Row
          imageComponent={imageComponent}
          isRoundImage={isRoundImage}
          playButtonComponent={playButtonComponent}
          subtitleComponent={subtitle}
          title={name}
          url={url}
        />
        <Tile
          dropdown={typeMap.dropdown[seedType] ? dropdownComponent : null}
          isRoundImage={isRoundImage}
          noTileOnMobile
          subTitle={subtitle}
          tileDelay={0}
          tilesInRow={TILES_IN_ROW}
          title={name}
          url={url}
        >
          <NavLink css={{ display: 'block', position: 'relative' }} to={url}>
            {playButtonComponent}
            <TilesImageWrapper liveTile={seedType === SavedStationTypes.Live}>
              {imageComponent}
            </TilesImageWrapper>
          </NavLink>
        </Tile>
      </Fragment>
    );
  });

  const noTiles = hasFetched && tiles.length === 0;

  return (
    <>
      <H4
        data-test="your-library-user-stations-title"
        hideHeader={noTiles}
        hideInMobile={noTiles}
      >
        <SectionHeaderText>{translate('Followed Stations')}</SectionHeaderText>
      </H4>

      <ShouldShow shouldShow={!noTiles}>
        <BR />
        <TilesWrapper>
          <InfiniteScroll hasMore={hasMore} loadMore={loadMore} pageStart={0}>
            <Tiles noTileOnMobile tilesInRow={TILES_IN_ROW}>
              {tiles}
            </Tiles>
          </InfiniteScroll>
        </TilesWrapper>
      </ShouldShow>

      <ShouldShow shouldShow={noTiles}>
        <EmptyState
          buttons={[
            <NavLink key="live-empty-link" to="/live/">
              <OutlinedButton>{translate('Browse Live Radio')}</OutlinedButton>
            </NavLink>,
            <NavLink
              dataTest="artist-radio-empty-link"
              key="artist-empty-link"
              to="/artist/"
            >
              <OutlinedButton>
                {translate('Browse Artist Radio')}
              </OutlinedButton>
            </NavLink>,
          ]}
          icon={LiveRadioIcon}
          subtitle={translate('Need help finding stations to save?')}
          title={translate(
            'Your followed live and artist radio stations will show up here',
          )}
        />
      </ShouldShow>
    </>
  );
}

export default SavedStations;
